import { assessmentHandler } from '../../services/AssessmentHandlers';
import { assetHandler } from '../../services/AssetHandlers';
import { facilityHandler } from '../../services/FacilityHandlers';
import { organizationHandler } from '../../services/OrganizationHandlers';
import { planHandler } from '../../services/PlanHandlers';
import { projectHandler } from '../../services/ProjectHandlers';
import { scenarioHandler } from '../../services/ScenarioHandlers';
import { settingsHandler } from '../../services/SettingsHandlers';
import { userHandler } from '../../services/UserHandlers';
export const platformHandlers = [
    ...assetHandler.handlers,
    ...projectHandler.handlers,
    ...assessmentHandler.handlers,
    ...organizationHandler.handlers,
    ...facilityHandler.handlers,
    ...userHandler.handlers,
    ...scenarioHandler.handlers,
    ...settingsHandler.handlers,
    ...planHandler.handlers,
];
