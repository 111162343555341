import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Stack, useToken, VStack } from '@chakra-ui/react';
import { useAppAccess } from '@frontend/domain/contexts/Authorization/UserAccess';
import { usePlannerSettingsContext } from '@frontend/domain/contexts/Settings/PlannerSettingsContext';
import { IAppNames } from '@frontend/domain/models/App';
import { AgeAdjustedConditionToggle } from './AgeAdjustedConditionToggle';
import { EscalationFactorContainer } from './EscalationFactorContainer';
import { FacilityGroupersContainer } from './FacilityGroupersContainer';
import { FundingTypesContainer } from './FundingTypesContainer';
import { PrioritizationAlgorithmsContainer } from './PrioritizationAlgorithmsContainer';
import { ProcurementTypesContainer } from './ProcurementTypesContainer';
import { ProjectTypesContainer } from './ProjectTypesContainer';
export const MyPlanningPage = ({ canEdit }) => {
    const { orgFundingTypes, upsertFundingType, deleteFundingType, orgProjectTypes, upsertProjectType, deleteProjectType, orgProcurementTypes, upsertProcurementType, deleteProcurementType, orgEscalationFactor, generalUpdateEscalationFactor, orgAgeAdjustedCondition, updateAgeAdjustedCondition, orgFacilityGroupers, updateFacilityGrouperName, orgPrioritizationAlgorithms, priorityCriteria, upsertPrioritizationAlgorithm, refreshPriorityCriteria, } = usePlannerSettingsContext();
    const { hasAccess: showGroupers } = useAppAccess(IAppNames.PIQ);
    const [dark] = useToken('colors', ['secondary.dark.200']);
    return (_jsxs(Stack, { spacing: 5, children: [_jsxs(Grid, { templateColumns: showGroupers ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)', gap: 10, border: `1px solid ${dark}`, borderRadius: '1px', justifyContent: 'stretch', alignItems: 'flex-start', p: 10, children: [_jsx(FundingTypesContainer, { orgFundingTypes: orgFundingTypes, upsertFundingType: upsertFundingType, deleteFundingType: deleteFundingType, canEdit: canEdit }), _jsx(ProcurementTypesContainer, { orgProcurementTypes: orgProcurementTypes, upsertProcurementType: upsertProcurementType, deleteProcurementType: deleteProcurementType, canEdit: canEdit }), showGroupers && (_jsx(FacilityGroupersContainer, { orgFacilityGroupers: orgFacilityGroupers, updateFacilityGrouperName: updateFacilityGrouperName, canEdit: canEdit })), _jsxs(VStack, { spacing: 5, children: [_jsx(AgeAdjustedConditionToggle, { canEdit: canEdit, hasAgeAdjustedCondition: orgAgeAdjustedCondition, updateAgeAdjustedCondition: updateAgeAdjustedCondition }), _jsx(EscalationFactorContainer, { orgEscalationFactor: orgEscalationFactor, generalUpdateEscalationFactor: generalUpdateEscalationFactor, canEdit: canEdit })] })] }), _jsxs(Grid, { templateColumns: 'repeat(2, 1fr)', w: '100%', gap: 10, border: `1px solid ${dark}`, borderRadius: '1px', alignItems: 'flex-start', p: 10, mb: 8, children: [_jsx(PrioritizationAlgorithmsContainer, { canEdit: canEdit, orgPrioritizationAlgorithms: orgPrioritizationAlgorithms, upsertPrioritizationAlgorithm: upsertPrioritizationAlgorithm, priorityCriteria: priorityCriteria, refreshPriorityCriteria: refreshPriorityCriteria }), _jsx(ProjectTypesContainer, { canEdit: canEdit, orgProjectTypes: orgProjectTypes, orgPrioritizationAlgorithms: orgPrioritizationAlgorithms, upsertProjectType: upsertProjectType, deleteProjectType: deleteProjectType })] })] }));
};
