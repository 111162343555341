import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { HStack } from '@chakra-ui/react';
import Button from '@frontend/design-system/components/Button/Button';
import { SettingsPageMode } from '@frontend/domain/models/Settings/SettingsInterfaces';
export const SettingsSaveCancelBtnGroup = ({ btnLabel, validationError, data, onSave, onCancel, }) => {
    return (_jsxs(HStack, { gap: 0, children: [_jsx(Button, { isIcon: true, ariaLabel: `Save ${btnLabel}`, buttonType: 'customLink', icon: _jsx(CheckIcon, {}), isDisabled: validationError !== undefined, onClick: () => {
                    if (data) {
                        onSave(data);
                    }
                } }), _jsx(Button, { isIcon: true, ariaLabel: `Cancel upsert ${btnLabel}`, buttonType: 'customLink', icon: _jsx(CloseIcon, {}), onClick: () => onCancel(SettingsPageMode.VIEW) })] }));
};
