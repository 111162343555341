export const getChakraStyles = (inputHeight, selectLabel) => ({
    control: (provided) => (Object.assign(Object.assign({}, provided), { minHeight: inputHeight, height: 'full', paddingY: 0, paddingX: 2, borderRadius: selectLabel ? '0px 8px 8px 0px' : '8px', borderWidth: '1px', boxSizing: 'border-box' })),
    placeholder: (provided) => (Object.assign(Object.assign({}, provided), { fontSize: '14px' })),
    inputContainer: (provided) => (Object.assign(Object.assign({}, provided), { fontSize: '14px', alignItems: 'center' })),
    singleValue: (provided) => (Object.assign(Object.assign({}, provided), { fontSize: '14px', alignItems: 'center' })),
    valueContainer: (provided) => (Object.assign(Object.assign({}, provided), { alignItems: 'center', paddingY: 0 })),
    indicatorsContainer: (provided) => (Object.assign(Object.assign({}, provided), { display: 'flex', alignItems: 'center' })),
    container: (provided) => (Object.assign(Object.assign({}, provided), { width: '100%' })),
    option: (provided, state) => (Object.assign(Object.assign({}, provided), { background: state.isSelected
            ? 'primary.orange.300'
            : state.isFocused
                ? 'primary.orange.100'
                : 'transparent', color: 'white', _hover: {
            background: state.isSelected
                ? 'primary.orange.300'
                : 'primary.orange.100',
        }, _active: {
            background: state.isSelected
                ? 'primary.orange.300'
                : 'primary.orange.100',
        }, _selected: {
            background: 'primary.orange.300',
        } })),
    multiValue: (provided) => (Object.assign(Object.assign({}, provided), { fontSize: '10px', h: 5, borderRadius: 'full', background: 'primary.orange.200', _hover: {
            background: 'primary.orange.300',
        }, _selected: {
            background: 'primary.orange.200',
        } })),
    multiValueRemove: (provided) => (Object.assign(Object.assign({}, provided), { fontSize: '14px', p: 0 })),
});
