import { defineStyleConfig, extendTheme } from '@chakra-ui/react';
// This component exists in Chakra, probably dont need a separate package
import { StepsTheme as Steps } from 'chakra-ui-steps';
import colors from './colors';
import { appColors, appValues } from './constants';
import spacing from './spacing';
import textStyles from './textStyles';
export const opacityHex = {
    25: '40',
    60: '99',
    80: 'CC',
    // Deprecated
    ten: '1A',
    thirty: '4D',
    sixty: '99',
};
export const variants = {
    gradientBtn: 'gradient-btn',
    blueTransparentBtn: 'blue-transparent-btn',
    cpBlueTransparentBtn: 'cp-trans-blue-btn',
    transparentReportBtn: 'transparent-report-btn',
    transparentIcoBtn: 'transparent-ico-btn',
    detailsInputText: 'details-input-text',
    editBtn: 'edit-btn',
    error: 'error',
    orangeBtn: 'orange-btn',
    orangeTransparentBtn: 'orange-transparent-btn',
    cpOrangeTransparentBtn: 'cp-orange-transparent-btn',
    orangeTransparentModalBtn: 'orange-transparent-modal-btn',
    addNewBtn: 'add-new-btn',
    transparentBlueBtn: 'trans-blue-btn',
    // Duplciate from FCA
    fcaTransparentBlueBtn: 'fca-trans-blue-btn',
    blueOutlineBtn: 'blue-outline-btn',
    orangeHoverLink: 'orange-hover-link',
    secDarkGrayBtn: 'sec-dark-gray-btn',
    linkBtn: 'link-btn',
    sidebarBtn: 'sidebar-btn',
    transDelBtn: 'trans-del-btn',
    primaryTransparent: 'primary-transparent',
    blueBtn: 'blue-btn',
    outline: 'outline',
    // Duplciate from CP
    cpBlueBtn: 'cp-blue-btn',
    // Duplciate from FCA
    fcaBlueBtn: 'fca-blue-btn',
    selectRounded: 'select-rounded',
    // TODO: not used by FCA because issues with bringing ds theme into app (FCA has own variant def)
    selectCheckbox: 'select-checkbox',
    // CP Specific:
    title: 'title',
    widgetsPage: 'widgets-page',
    comparisonTitle: 'comparisonTitle',
    boldText: 'bold-text',
    regText: 'reg-text',
    boldHeading: 'bold-heading',
    boldGrayHeading: 'bold-gray-heading',
    redOutlineBtn: 'red-outline-btn',
    grayBtn: 'gray-btn',
    subText: 'sub-text',
    cpBaseStyle: 'cpBaseStyle',
    // FCA Specific:
    regSizeH: 'regular-size',
    detailsSidebar: 'details-sidebar',
    blueSemiBText: 'blue-semi-bold-txt',
    checkboxSelect: 'select-checkbox',
    selectOptions: 'select-options',
    transparentBtn: 'transparent-btn',
    transparentRedBtn: 'trans-red-btn',
    h2: 'h2',
    galleryHeader: 'gallery-header',
    transparentTab: 'transparent-tab',
    whiteBtn: 'white-btn',
    fcaError: 'error',
    fcaBaseStyle: 'fcaBaseStyle',
    msiqBaseStyle: 'msiqBaseStyle',
};
// CP Specific START
export const layerStyles = {
    widgetsPageContainer: 'widgets-page-container',
};
export const slgqColorSchemes = {
    primBlue: 'primBlue',
    secDarkGray: 'secDarkGray',
    textColor: 'textColor',
};
const CustomSteps = Object.assign(Object.assign({}, Steps), { baseStyle: (props) => {
        return Object.assign(Object.assign({}, Steps.baseStyle(props)), { icon: Object.assign(Object.assign({}, Steps.baseStyle(props).icon), { strokeWidth: '1px' }), steps: Object.assign(Object.assign({}, Steps.baseStyle(props).steps), { sizes: {
                    lg: {
                        label: {
                            fontSize: '10.2rem',
                        },
                    },
                } }) });
    } });
const ListStyling = defineStyleConfig({
    baseStyle: { bg: 'red' },
    variants: {
        'widgets-page': {
            borderWidth: '1px',
            borderColor: '#D6D7DB',
            boxSizing: 'border-box',
            p: '2px',
            borderRadius: '2px',
        },
    },
});
// CP Specific END
// FCA Specific START
export const slgqColors = {
    primBlue: 'slgq.primBlue',
    primLightGrey: 'slqg.primLightGrey',
    primOrange: 'slgq.primOrange',
    primWhite: 'slgq.primWhite',
    secGrey: 'slgq.secGrey',
    secLightGrey: 'slgq.secLightGrey',
    secDarkGrey: 'slgq.secDarkGray',
    secOrange: 'slgq.secOrange',
};
// FCA Specific END
const theme = extendTheme({
    colors: Object.assign(Object.assign({}, colors), { 
        // Deprecated: only in use for backwards compatibility, please use new colors in colors.ts
        brand: {
            primBlue: '#003B5D',
            primOrange: {
                30: '#FBDABD',
            },
            secOrange: '#F6862A',
            modalOrange: '#DA1100',
            deleteError: '#DA1100',
            secDarkGray: '#818584',
            errRed: '#F74440',
            wrnYellow: '#FFBE18',
            successGreen: '#15803D',
            primLightGrey: '#C0C2C1',
            primWhite: '#FAF9F7',
            secGrey: '#2E3532',
            secLightGrey: '#F2F3F4',
            tertBlue: '#6C899E',
        }, secondaryDarkGray: {
            300: '#C0C2C1',
            800: '#2E3532',
        }, orange: {
            '500': appColors.SEC_ORANGE,
        }, yellow: '#FEDB5E', primBlue: {
            '50': '#dfefff',
            '60': '#6C899E',
            '500': appColors.PRIM_BLUE,
            '600': '#000f1e',
        }, 
        // FCA Duplicate
        fcaPrimBlue: {
            '600': '#0e527e',
            '500': '#003B5D',
        }, secDarkGray: {
            '300': '#F2F3F4',
            '500': appColors.SEC_DARK_GRAY,
            '600': '#717574',
        }, secondaryLightGray: '#D6D7DB', slgq: {
            white: '#FFFFFF',
            // FCA Specific
            primBlue: '#003B5D',
            primLightGrey: '#C0C2C1',
            primOrange: '#FBDABD',
            primWhite: '#FAF9F7',
            secGrey: '#2E3532',
            secDarkGray: '#818584',
            secLightGrey: '#F2F3F4',
            secOrange: '#F6862A',
            tertBlue: '#6C899E',
            yellow: '#FEDB5E',
        }, misc: {
            sidebarWhite: '#FAF9F7',
            bgGrey: '#DADCDE',
            sectionGrey: '#E6E7E9',
        } }),
    space: Object.assign({}, spacing),
    fonts: {
        nav: 'montserrat',
        heading: 'montserrat',
        body: 'montserrat',
        label: 'montserrat',
        header: 'montserrat',
        section: 'montserrat',
        footer: 'montserrat',
    },
    styles: {
        global: {
            a: {
                _hover: {
                    textDecoration: 'underline',
                },
            },
            // FCA Specific
            h2: {
                color: slgqColors.primBlue,
            },
        },
    },
    // Was really hoping we'd be able to reuse in other components (button, input, etc)
    // There is a workaround for components that don't natively support textStyle
    // variants: { custom: (props) => ({...props.theme.textStyles.body, color: '', _hover: {}}) },
    textStyles: Object.assign({}, textStyles),
    layerStyles: {
        thead: {
            bg: '#F2F3F4',
        },
        'widgets-page-container': {
            borderRadius: '2px',
            borderWidth: '1px',
            borderColor: `${appColors.SEC_DARK_GRAY}${opacityHex.thirty}`,
            display: 'block',
            p: '10px',
        },
    },
    borderStyles: {
        border: {
            primary: '1px solid #D6D7DB',
        },
    },
    // v3 of Chakra UI should allow us to use opacity with the color to avoid hardcoding
    shadows: {
        dropPrimary: '0px 0px 4px 0px #00000040',
        dropBottom: '0px 1px 2px 0px #00000040',
        innerPrimary: '0px 0px 4px 0px #00000040 inset',
        innerTop: '0px 4px 4px 0px #00000040 inset',
    },
    components: {
        Button: {
            variants: {
                primary: {
                    background: 'primary.blue.500',
                    color: 'base.white',
                    fontSize: '14px',
                    fontWeight: 'medium',
                    lineHeight: '20px',
                    borderRadius: 'full',
                    border: '1px solid',
                    boxShadow: 'innerPrimary',
                    _hover: {
                        boxShadow: 'dropBottom',
                        _disabled: {
                            background: 'secondary.dark.300',
                            boxShadow: 'none',
                        },
                    },
                    _disabled: {
                        background: 'secondary.dark.300',
                        opacity: 1,
                    },
                    _active: {
                        boxShadow: 'innerPrimary',
                    },
                },
                secondary: {
                    background: 'transparent',
                    border: '1px solid',
                    borderColor: 'primary.blue.500',
                    color: 'primary.blue.500',
                    fontSize: '14px',
                    fontWeight: 'medium',
                    lineHeight: '20px',
                    borderRadius: 'full',
                    // This is added so that the button doesn't change size when clicked with the thicker border
                    m: '1px',
                    _hover: {
                        textShadow: '0px 4px 4px #00000040',
                        boxShadow: 'dropBottom',
                        _disabled: {
                            boxShadow: 'none',
                            textShadow: 'none',
                            m: '1px',
                        },
                    },
                    _disabled: {
                        color: 'secondary.dark.300',
                        borderColor: 'secondary.dark.300',
                        opacity: 1,
                    },
                    _active: {
                        m: '0px',
                        border: '2px solid',
                        boxShadow: 'none',
                        textShadow: 'none',
                        _disabled: {
                            border: '1px solid',
                        },
                    },
                },
                customLink: {
                    color: 'primary.blue.500',
                    fontSize: '14px',
                    fontWeight: 'medium',
                    lineHeight: '20px',
                    borderRadius: 'full',
                    _hover: {
                        boxShadow: 'innerPrimary',
                        _disabled: {
                            boxShadow: 'none',
                        },
                    },
                    _disabled: {
                        color: 'secondary.dark.300',
                        opacity: 1,
                    },
                },
                // deprecated styles, use above moving forward
                cpBaseStyle: {
                    borderRadius: '40px',
                    h: '40px',
                },
                'sidebar-btn': {
                    background: '#EDF2F7',
                    dropShadow: 'rgba(0, 0, 0, 0.25)',
                    borderShadow: '50px 0px 0px 50px',
                    opacity: 0.8,
                    borderRadius: '50px 0px 0px 50px',
                    p: 0,
                    m: 0,
                },
                'transparent-ico-btn': {
                    bg: 'transparent',
                    _hover: { bg: 'transparent' },
                    _active: { boxShadow: 'none' },
                },
                'gradient-btn': {
                    bgGradient: 'linear(92.97deg, brand.primBlue 15.24%, #6C899E 112.39%)',
                    color: 'white',
                    textTransform: 'uppercase',
                    borderRadius: '60px',
                    boxSizing: 'border-box',
                    py: '10px',
                    px: '35px',
                    h: '40px',
                },
                'blue-transparent-btn': {
                    bg: 'transparent',
                    color: 'brand.primBlue',
                    textTransform: 'uppercase',
                    borderWidth: '1px',
                    borderColor: 'brand.primBlue',
                    borderRadius: '60px',
                    boxSizing: 'border-box',
                    py: '10px',
                    px: '35px',
                    h: '40px',
                },
                'transparent-report-btn': {
                    bg: 'transparent',
                    color: 'brand.primBlue',
                    borderColor: 'brand.primBlue',
                    borderWidth: '1px',
                    borderRadius: '10px',
                    h: '30px',
                    fontWeight: 500,
                    _hover: { bg: 'brand.primBlue', color: 'white' },
                    transitionDuration: appValues.TRANSITION_DURATION,
                },
                'trans-del-btn': {
                    bg: 'transparent',
                    color: 'brand.deleteError',
                    borderWidth: '1px',
                    borderColor: 'brand.deleteError',
                    borderRadius: '60px',
                    boxSizing: 'border-box',
                    py: '10px',
                    px: '35px',
                    h: '40px',
                },
                'edit-btn': {
                    bg: 'brand.primBlue',
                    color: 'white',
                    fontSize: 18,
                    fontWeight: 400,
                    borderRadius: '32px',
                    boxSizing: 'border-box',
                    py: '10px',
                    px: '35px',
                    w: '152px',
                    h: '40px',
                },
                'orange-btn': {
                    bg: 'brand.secOrange',
                    color: 'white',
                    borderRadius: '60px',
                    fontWeight: 700,
                    fontSize: 14,
                },
                'orange-transparent-btn': {
                    bg: 'transparent',
                    color: 'brand.secOrange',
                    borderWidth: '1px',
                    borderColor: 'brand.secOrange',
                    borderRadius: '60px',
                    boxSizing: 'border-box',
                    fontWeight: 700,
                    fontSize: 14,
                },
                // CP
                'cp-orange-transparent-btn': {
                    bg: 'transparent',
                    color: 'brand.secOrange',
                    borderWidth: '1px',
                    borderColor: 'brand.secOrange',
                    borderRadius: '18px',
                    boxSizing: 'border-box',
                    py: '8px',
                    px: '20px',
                    h: '36px',
                },
                'orange-transparent-modal-btn': {
                    bg: 'transparent',
                    color: 'brand.modalOrange',
                    borderWidth: '1px',
                    borderColor: 'brand.modalOrange',
                    borderRadius: '60px',
                    boxSizing: 'border-box',
                    fontWeight: 700,
                    fontSize: 14,
                },
                'add-new-btn': {
                    bg: 'brand.primBlue',
                    color: 'white',
                    borderRadius: '60px',
                    boxSizing: 'border-box',
                    fontWeight: 400,
                    fontSize: 18,
                    '&[disabled]': {
                        '&:hover': {
                            bg: 'brand.primBlue',
                            color: 'white',
                        },
                    },
                },
                'blue-outline-btn': {
                    bg: 'white',
                    color: appColors.PRIM_BLUE,
                    border: `1px solid ${appColors.PRIM_BLUE}`,
                    borderRadius: '40px',
                    boxSizing: 'border-box',
                    fontWeight: 500,
                    fontSize: '14px',
                    height: '36px',
                },
                'trans-blue-btn': {
                    bg: 'transparent',
                    color: 'brand.primBlue',
                    borderWidth: '1px',
                    borderColor: 'brand.primBlue',
                    borderRadius: '18px',
                    boxSizing: 'border-box',
                    fontSize: '14px',
                    py: '8px',
                    px: '20px',
                    h: '36px',
                },
                // FCA
                'fca-trans-blue-btn': {
                    bg: 'transparent',
                    color: slgqColors.primBlue,
                    borderWidth: '1px',
                    borderColor: slgqColors.primBlue,
                    borderRadius: '18px',
                    boxSizing: 'border-box',
                    fontSize: '14px',
                    py: '8px',
                    px: '20px',
                    h: '30px',
                },
                // CP - this is actually blueTransparentBtn from above
                'cp-trans-blue-btn': {
                    bg: 'transparent',
                    color: 'brand.primBlue',
                    borderWidth: '1px',
                    borderColor: 'brand.primBlue',
                    borderRadius: '18px',
                    boxSizing: 'border-box',
                    py: '8px',
                    px: '20px',
                    h: '36px',
                    fontWeight: 500,
                },
                'sec-dark-gray-btn': {
                    bg: '#818584',
                    color: 'white',
                    borderRadius: '60px',
                    boxSizing: 'border-box',
                    fontWeight: 700,
                    fontSize: 14,
                    opacity: 1,
                },
                'link-btn': {
                    bg: 'transparent',
                    fontWeight: 500,
                    px: 0,
                    borderRadius: 0,
                    py: '3px',
                    h: 'auto',
                },
                'primary-transparent': {
                    bg: 'transparent',
                    color: 'brand.primBlue',
                    p: 0,
                    h: 'fit-content',
                    fontSize: '14px',
                },
                'blue-btn': {
                    bg: 'brand.primBlue',
                    color: 'white',
                    borderRadius: '18px',
                    boxSizing: 'border-box',
                    fontWeight: 400,
                    fontSize: '14px',
                    py: '8px',
                    px: '20px',
                    height: '36px',
                    _disabled: {
                        // Chakra seems to enforce a white background when hovering over a disabled button
                        // Would like to find an alternative to !important
                        _hover: { bg: 'brand.primBlue !important' },
                    },
                },
                // CP
                'cp-blue-btn': {
                    bg: appColors.PRIM_BLUE,
                    color: 'white',
                    borderRadius: '40px',
                    boxSizing: 'border-box',
                    fontWeight: 500,
                    fontSize: '14px',
                    height: '40px',
                },
                // FCA
                'fca-blue-btn': {
                    bg: slgqColors.primBlue,
                    color: 'white',
                    borderRadius: '18px',
                    boxSizing: 'border-box',
                    fontWeight: 400,
                    fontSize: '14px',
                    py: '8px',
                    px: '20px',
                    height: '30px',
                    '&[disabled]': {
                        '&:hover': {
                            bg: 'brand.primBlue',
                            color: 'white',
                        },
                    },
                },
                // CP Specific
                'gray-btn': {
                    bg: appColors.SEC_DARK_GRAY,
                    color: 'white',
                    borderRadius: '40px',
                    boxSizing: 'border-box',
                    fontWeight: 500,
                    fontSize: '14px',
                    height: '36px',
                    width: '106px',
                },
                'red-outline-btn': {
                    bg: 'white',
                    color: appColors.DELETE_ERROR,
                    border: `1px solid ${appColors.DELETE_ERROR}`,
                    borderRadius: '40px',
                    boxSizing: 'border-box',
                    fontWeight: 500,
                    fontSize: '14px',
                    height: '36px',
                    width: '106px',
                },
                // FCA Specific
                fcaBaseStyle: {
                    background: '#EDF2F7',
                    borderRadius: '6px',
                },
                'transparent-btn': {
                    bg: 'transparent',
                    boxSizing: 'border-box',
                    p: '5px',
                    fontSize: '14px',
                    height: '30px',
                },
                'trans-red-btn': {
                    bg: 'transparent',
                    color: appColors.DELETE_ERROR,
                    borderWidth: '1px',
                    borderColor: appColors.DELETE_ERROR,
                    borderRadius: '18px',
                    boxSizing: 'border-box',
                    fontSize: '14px',
                    py: '8px',
                    px: '20px',
                    h: '30px',
                },
                'white-btn': {
                    bg: slgqColors.primWhite,
                    borderRadius: '60px',
                    border: '1px',
                    borderColor: slgqColors.primBlue,
                    color: slgqColors.primBlue,
                    boxSizing: 'border-box',
                    fontWeight: 700,
                    fontSize: '14px',
                    height: '30px',
                    w: '45%',
                },
            },
        },
        // TODO: not used by FCA because issues with bringing ds theme into app (FCA has own variant def)
        Checkbox: {
            baseStyle: {
                // this came from CP
                size: 'md',
                // this came from FCA
                verticalAlign: 'center',
            },
            defaultProps: {
                color: appColors.SEC_ORANGE,
                colorScheme: '#FFFFFF',
            },
            variants: {
                'select-checkbox': {
                    icon: {
                        color: 'brand.secOrange',
                    },
                    control: {
                        borderColor: 'brand.primBlue',
                        backgroundColor: 'brand.primWhite',
                        _disabled: {
                            // this is brand.primLightGrey in FCA
                            backgroundColor: 'brand.secLightGrey',
                            borderColor: 'brand.secDarkGray',
                        },
                    },
                },
            },
        },
        FormLabel: {
            baseStyle: {
                boxSizing: 'border-box',
                color: appColors.PRIM_BLUE,
                fontWeight: 'semibold',
                fontSize: 14,
                mb: '5px',
                pl: '18px',
            },
            variants: {
                // FCA
                fcaBaseStyle: {
                    color: slgqColors.primBlue,
                    boxSizing: 'border-box',
                    fontWeight: 600,
                    fontSize: 14,
                    mb: '8px',
                    pl: 0,
                },
                // TODO: remove this once all inputs are using design system inputs
                designSystem: {
                    pl: 0,
                    mb: 0,
                },
            },
        },
        Link: {
            variants: {
                'orange-hover-link': {
                    p: '2px',
                    _hover: {
                        bg: 'brand.secOrange',
                        color: 'white',
                        textDecoration: 'none',
                    },
                },
            },
        },
        Heading: {
            baseStyle: {
                fontWeight: 400,
                fontSize: 32,
            },
            variants: {
                // CP Specific
                h3: {
                    fontSize: 14,
                    fontWeight: 600,
                },
                'h3-v2': {
                    fontWeight: 600,
                    fontSize: '20px',
                },
                'bold-heading': {
                    fontWeight: 500,
                    fontSize: '10px',
                    color: appColors.PRIM_BLUE,
                },
                'bold-gray-heading': {
                    fontWeight: 500,
                    fontSize: '10px',
                    color: '#2E3532',
                },
                'clickable-name': {
                    cursor: 'pointer',
                    _hover: {
                        textDecoration: 'underline',
                    },
                },
                // FCA Specific
                'regular-size': {
                    fontSize: 16,
                },
                h2: {
                    fontWeight: 400,
                    fontSize: 26,
                },
                'details-sidebar': {
                    fontSize: '14px',
                    py: '10px',
                    textAlign: 'center',
                    lineHeight: '20px',
                    fontWeight: 400,
                },
                'gallery-header': {
                    fontWeight: 400,
                    fontSize: 30,
                    color: 'black',
                    mt: '40px',
                    mb: '20px',
                },
            },
        },
        Input: {
            baseStyle: {
                field: {
                    borderRadius: '8px',
                    h: '40px',
                    boxSizing: 'border-box',
                    px: '18px',
                    '&[disabled]': {
                        color: '#818584',
                        bg: '#F2F3F4',
                        border: '1px solid #D6D7DB',
                        boxShadow: 'unset',
                    },
                },
            },
            variants: {
                base: {
                    field: {
                        // textStyle doesn't apply font sizes to the Input component
                        fontSize: '14px',
                        lineHeight: '22px',
                        bg: 'base.white',
                        border: '1px solid #D6D7DB',
                        _disabled: {
                            bg: 'secondary.light.200',
                            border: '1px solid',
                            borderColor: 'secondary.dark.200',
                        },
                        _focus: {
                            borderColor: 'primary.blue.300',
                        },
                        _invalid: {
                            borderColor: 'ui.danger',
                        },
                    },
                },
                fcaBaseStyle: {
                    field: {
                        _placeholder: {
                            opacity: 1,
                            color: slgqColors.primLightGrey,
                            wordBreak: 'break-word',
                            fontSize: '14px',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        },
                        fontFamily: 'montserrat',
                        borderRadius: '8px',
                        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15) inset',
                        boxSizing: 'border-box',
                        px: '18px',
                        h: '36px',
                    },
                },
                'round-input': {
                    field: {
                        fontFamily: 'mulish',
                        borderRadius: '50px',
                        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
                        h: '40px',
                        boxSizing: 'border-box',
                        px: '18px',
                    },
                },
                backgroundFix: {
                    field: {
                        bg: '#FFFFFF',
                    },
                },
                // CP
                outline: {
                    field: {
                        borderColor: '#C0C2C1',
                        backgroundColor: 'white',
                    },
                },
                cpBaseStyle: {
                    field: {
                        _placeholder: {
                            color: appColors.SEC_DARK_GRAY,
                            opacity: 1,
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        },
                        borderRadius: '8px',
                        borderWidth: '1px',
                        boxSizing: 'border-box',
                        fontSize: '14px',
                        h: '40px',
                        px: '18px',
                    },
                },
            },
        },
        List: ListStyling,
        Modal: {
            baseStyle: {
                header: {
                    color: '#2E3532',
                    fontSize: 32,
                    fontWeight: 400,
                },
                dialog: {
                    fontFamily: 'montserrat',
                    borderRadius: '15px',
                },
            },
        },
        NumberInput: {
            baseStyle: {
                field: {
                    borderRadius: '8px',
                    h: '40px',
                    boxSizing: 'border-box',
                    px: '18px',
                    '&[disabled]': {
                        color: '#818584',
                        bg: '#F2F3F4',
                        border: '1px solid #D6D7DB',
                        boxShadow: 'unset',
                    },
                },
            },
            defaultProps: {
                size: null,
                variant: 'backgroundFix', // FCA
            },
            variants: {
                // MSIQ Base
                msiqBaseStyle: {
                    field: {
                        borderRadius: '50px',
                        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
                        h: '40px',
                        boxSizing: 'border-box',
                        px: '18px',
                        '&[disabled]': {
                            color: '#818584',
                            bg: '#F2F3F4',
                            border: '1px solid #D6D7DB',
                            boxShadow: 'unset',
                        },
                    },
                },
                // FCA
                fcaBaseStyle: {
                    field: {
                        _placeholder: {
                            opacity: 1,
                            color: slgqColors.primLightGrey,
                            wordBreak: 'break-word',
                            fontSize: '14px',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        },
                        fontFamily: 'montserrat',
                        borderRadius: '8px',
                        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15) inset',
                        boxSizing: 'border-box',
                        px: '18px',
                        h: '36px',
                    },
                },
                'round-input': {
                    field: {
                        fontFamily: 'mulish',
                        borderRadius: '50px',
                        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
                        h: '40px',
                        boxSizing: 'border-box',
                        px: '18px',
                    },
                },
                backgroundFix: {
                    field: {
                        bg: '#FFFFFF',
                    },
                },
            },
            // CP
            outline: {
                field: {
                    borderColor: '#C0C2C1',
                    backgroundColor: 'white',
                },
            },
            cpBaseStyle: {
                field: {
                    _placeholder: {
                        color: appColors.SEC_DARK_GRAY,
                        opacity: 1,
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                    },
                    borderRadius: '8px',
                    borderWidth: '1px',
                    boxSizing: 'border-box',
                    fontSize: '14px',
                    h: '40px',
                    px: '18px',
                },
            },
        },
        Radio: {
            baseStyle: {
                control: {
                    _checked: {
                        bg: 'base.white',
                        borderColor: 'primary.blue.500',
                        _before: {
                            bg: 'primary.blue.500',
                        },
                    },
                },
            },
        },
        Select: {
            baseStyle: {
                field: {
                    borderRadius: '8px',
                    background: 'white',
                    h: '40px',
                    fontSize: '14px',
                    // boxSizing: 'border-box',
                    px: '18px',
                    '&[disabled]': {
                        color: '#818584',
                        bg: '#F2F3F4',
                        border: '1px solid #D6D7DB',
                        boxShadow: 'unset',
                    },
                },
            },
            variants: {
                // CP Specific
                'cp-base': {
                    field: {
                        borderRadius: '8px',
                        fontSize: '14px',
                        backgroundColor: 'white',
                    },
                },
                'cp-unstyled': {
                    overflow: 'hidden',
                    field: {
                        borderRadius: '8px',
                        fontSize: '14px',
                        backgroundColor: 'white',
                        boxShadow: 'none',
                        overflow: 'hidden',
                        '&[disabled]': {
                            color: '#818584',
                            bg: '#fff',
                            border: 'none',
                        },
                        _placeholder: { overflow: 'hidden' },
                        _hover: {
                            cursor: 'pointer',
                        },
                    },
                },
                outline: {
                    field: {
                        borderColor: '#C0C2C1',
                    },
                },
                // FCA Specific
                fcaBaseStyle: {
                    field: {
                        _placeholder: {
                            opacity: 1,
                            color: slgqColors.primLightGrey,
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        },
                        boxSizing: 'border-box',
                        fontWeight: 400,
                        textAlign: 'left',
                    },
                },
                'select-rounded': {
                    field: {
                        borderRadius: '50px',
                        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
                        h: '40px',
                        boxSizing: 'border-box',
                        px: '18px',
                        _disabled: {
                            color: slgqColors.secDarkGrey,
                            bg: '#F2F3F4',
                            border: '1px solid #D6D7DB',
                            boxShadow: 'unset',
                        },
                    },
                },
                backgroundFix: {
                    field: {
                        bg: '#FFFFFF',
                    },
                },
            },
            defaultProps: {
                size: null,
                // variant: 'backgroundFix', // FCA
            },
        },
        Steps: CustomSteps,
        Switch: {
            baseStyle: {
                track: {
                    _checked: {
                        bg: '#003B5D',
                    },
                },
            },
        },
        Tab: {
            variants: {
                'transparent-tab': {
                    _focus: { boxShadow: 'none' },
                },
            },
        },
        Tabs: {
            variants: {
                unstyled: {
                    parts: ['tab'],
                    tab: {
                        color: 'brand.primBlue',
                        fontSize: 24,
                        _selected: {
                            borderBottom: '4px solid #F6862A',
                            fontWeight: 700,
                        },
                    },
                },
                styled: {
                    parts: ['tab', 'tabpanel'],
                    tab: {
                        backgroundColor: '#6C899E',
                        color: 'white',
                        _selected: {
                            backgroundColor: '#F2F3F4',
                            color: 'secondaryDarkGray.800',
                            borderRadius: '4px 4px 0px 0px',
                            boxShadow: '0px 0px 4px 0px',
                        },
                        fontSize: '12px',
                        boxShadow: '0px 0px 4px 0px',
                        borderRadius: '4px 4px 0px 0px',
                    },
                    tabpanel: {
                        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
                    },
                },
            },
            defaultProps: {
                variant: 'unstyled',
            },
        },
        Table: {
            parts: ['table', 'th', 'td', 'tr'],
            baseStyle: {
                table: {
                    borderCollapse: 'separate',
                    borderSpacing: '0 10px',
                    border: 'none',
                    fontVariantNumeric: 'none',
                },
                th: {
                    fontWeight: 700,
                    textTransform: 'capitalize',
                    fontSize: 14,
                    letterSpacing: 'normal',
                    border: 'none',
                },
                td: {
                    borderTop: '1px solid',
                    borderBottom: '1px solid',
                },
                tr: {
                    borderRadius: '50px',
                    h: '65px',
                },
            },
            // CP Specific - can probably be removed as all tables reference design system now
            variants: {
                'border-none': {
                    table: {
                        borderCollapse: 'separate',
                    },
                    th: {
                        fontWeight: 500,
                        textTransform: 'capitalize',
                        px: '2px',
                    },
                    thead: {
                        fontWeight: 500,
                    },
                    td: {
                        border: 'none',
                    },
                    tr: {
                        border: 'none',
                    },
                },
                'simple-outline': {
                    table: {
                        borderCollapse: 'collapse',
                        borderSpacing: 0,
                    },
                    th: {
                        fontWeight: 500,
                        fontSize: 14,
                        borderBottom: '1px solid #C0C2C1',
                    },
                    thead: {
                        fontWeight: 500,
                    },
                    td: {
                        borderTop: 'none',
                        borderBottom: 'none',
                    },
                    tr: {
                        borderBottom: '1px solid #C0C2C1',
                        ':last-child': {
                            borderBottom: 'none',
                        },
                    },
                },
            },
        },
        Text: {
            // This comes from FCA
            baseStyle: {
                color: slgqColors.secGrey,
                fontFamily: 'montserrat',
            },
            variants: {
                'details-input-text': {
                    color: appColors.TEXT_COLOR,
                    boxSizing: 'border-box',
                    fontWeight: 400,
                    fontSize: '14px',
                    textAlign: 'left',
                    pl: '18px',
                },
                // CP Specific
                'cp-baseStyle': {
                    // handle this as base or make CP specific?
                    color: '#2E3532',
                },
                title: {
                    fontWeight: 600,
                    fontSize: '20px',
                },
                error: {
                    color: 'crimson',
                },
                'form-instructions': {
                    fontSize: 18,
                },
                slider: {
                    fontSize: 12,
                    color: appColors.PRIM_BLUE,
                },
                'bold-text': {
                    fontSize: '10px',
                    color: appColors.PRIM_BLUE,
                    fontWeight: 500,
                },
                'reg-text': {
                    fontSize: '10px',
                    color: '#2E3532',
                    fontWeight: 400,
                },
                'sub-text': {
                    fontWeight: 400,
                    size: '14px',
                    color: appColors.SEC_DARK_GRAY,
                    lineHeight: '22px',
                },
                'blue-semi-bold-text': {
                    color: slgqColors.primBlue,
                    boxSizing: 'border-box',
                    fontWeight: 600,
                    fontSize: '14px',
                },
                'fca-error': {
                    color: 'red.600',
                    pt: 1,
                    pl: 1,
                    fontSize: 'sm',
                },
            },
            // Is this even doing anything outside of base and variants?
            error: {
                color: 'red.600',
                pt: 1,
                pl: 1,
                fontSize: 'sm',
            },
        },
        Textarea: {
            baseStyle: {
                borderRadius: 'full',
                borderRightRadius: '8px',
                borderLeftRadius: '8px',
            },
            variants: {
                outline: {
                    borderColor: '#C0C2C1',
                    backgroundColor: 'white',
                },
                backgroundFix: {
                    field: {
                        bg: '#FFFFFF',
                    },
                },
                fcaBaseStyle: {
                    field: {
                        _placeholder: {
                            opacity: 1,
                            color: slgqColors.primLightGrey,
                            wordBreak: 'break-word',
                            fontSize: '14px',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            pl: '18px',
                        },
                        fontFamily: 'montserrat',
                        borderRadius: '8px',
                        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15) inset',
                        boxSizing: 'border-box',
                        px: '18px',
                        h: '36px',
                    },
                },
            },
        },
    },
});
export default theme;
