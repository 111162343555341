import { faker } from '@faker-js/faker';
import { CriteriaType } from '../models/Projects/ProjectsInterfaces';
export class SettingsResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ fundingTypes: [], projectTypes: [], procurementTypes: [], escalationFactor: faker.number.int({ min: 0, max: 1 }), useAgeAdjustedCondition: false, priorityAlgorithms: [], priorityCriteria: [], facilityGroupers: [] }, this.overrides);
        };
        this.overrides = overrides;
    }
    addFundingType(fundingTypeOverrides) {
        const fundingType = new FundingTypeSettingsResponseBuilder(fundingTypeOverrides).build();
        if (this.overrides.fundingTypes) {
            this.overrides.fundingTypes.push(fundingType);
        }
        else {
            this.overrides.fundingTypes = [fundingType];
        }
        return this;
    }
    addProjectType(projectTypeOverrides) {
        const projectType = new ProjectTypeSettingsResponseBuilder(projectTypeOverrides).build();
        if (this.overrides.projectTypes) {
            this.overrides.projectTypes.push(projectType);
        }
        else {
            this.overrides.projectTypes = [projectType];
        }
        return this;
    }
    addProcurementType(procurementTypeOverrides) {
        const procurementType = new ProcurementTypeSettingsResponseBuilder(procurementTypeOverrides).build();
        if (this.overrides.procurementTypes) {
            this.overrides.procurementTypes.push(procurementType);
        }
        else {
            this.overrides.procurementTypes = [procurementType];
        }
        return this;
    }
    addPriorityAlgorithm(priorityAlgorithmOverrides) {
        const priorityAlgorithm = new PriorityAlgorithmResponseBuilder(priorityAlgorithmOverrides).build();
        if (this.overrides.priorityAlgorithms) {
            this.overrides.priorityAlgorithms.push(priorityAlgorithm);
        }
        else {
            this.overrides.priorityAlgorithms = [priorityAlgorithm];
        }
        return this;
    }
    addPriorityCriteria(priorityCriteriaOverrides) {
        const priorityCriteria = new PriorityCriteriaResponseBuilder(priorityCriteriaOverrides).build();
        if (this.overrides.priorityCriteria) {
            this.overrides.priorityCriteria.push(priorityCriteria);
        }
        else {
            this.overrides.priorityCriteria = [priorityCriteria];
        }
        return this;
    }
    addFacilityGrouper(facilityGrouperOverrides) {
        const facilityGrouper = new FacilityGrouperResponseBuilder(facilityGrouperOverrides).build();
        if (this.overrides.facilityGroupers) {
            this.overrides.facilityGroupers.push(facilityGrouper);
        }
        else {
            this.overrides.facilityGroupers = [facilityGrouper];
        }
        return this;
    }
}
export class FundingTypeSettingsResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ fundingTypeId: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), fundingTypeName: faker.lorem.slug(1), isInUse: true }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class ProjectTypeSettingsResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ projectTypeId: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), projectTypeName: faker.lorem.slug(1), algorithmId: faker.number.int({
                    min: 1,
                    max: 4,
                }), isInUse: true }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class ProcurementTypeSettingsResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ procurementTypeId: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), procurementTypeName: faker.lorem.slug(1), isInUse: true }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class PriorityAlgorithmResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ algorithmId: faker.number.int({
                    min: 1,
                    max: 4,
                }), criteria: [
                    {
                        criteriaId: faker.number.int({
                            min: undefined,
                            max: undefined,
                        }),
                        criteriaName: faker.lorem.slug(1),
                        criteriaType: faker.helpers.enumValue(CriteriaType),
                        weight: faker.number.int({
                            min: 0,
                            max: 1,
                        }),
                    },
                ] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class PriorityCriteriaResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ criteriaId: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), criteriaName: faker.lorem.slug(1), criteriaType: faker.helpers.enumValue(CriteriaType) }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class FacilityGrouperResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ facilityGrouperNumber: faker.number.int({
                    min: 1,
                    max: 2,
                }), facilityGrouperName: faker.lorem.slug(1) }, this.overrides);
        };
        this.overrides = overrides;
    }
}
