import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { List } from '@chakra-ui/react';
import Button from '@frontend/design-system/components/Button/Button';
import { SettingsPageMode, } from '@frontend/domain/models/Settings/SettingsInterfaces';
import { useState } from 'react';
import { PrioritizationWeightInput } from './PrioritizationWeightInput';
export const PrioritizationAlgorithmCriteriaList = ({ currentTabIndex, prioritizationAlgorithms, algorithmCriteriaList, canEdit, pageMode, onRemove, acceptPrioritizations, setPageMode, criteriaOptions, priorityCriteria, }) => {
    const [algorithmIndexToAdd, setAlgorithmIndexToAdd] = useState(undefined);
    return (_jsx(_Fragment, { children: _jsxs(List, { spacing: 3, p: 5, children: [algorithmCriteriaList.length &&
                    algorithmCriteriaList.map((criteria, index) => {
                        return (_jsx(PrioritizationWeightInput, { canEdit: canEdit, pageMode: pageMode, originalData: criteria, onRemove: onRemove, prioritizationAlgorithms: prioritizationAlgorithms, algorithmCriteriaList: algorithmCriteriaList, acceptPrioritizations: acceptPrioritizations, setPageMode: setPageMode, currentTabIndex: currentTabIndex, criteriaOptions: criteriaOptions, setAlgorithmIndexToAdd: setAlgorithmIndexToAdd, priorityCriteria: priorityCriteria }, `criteria-${index}`));
                    }), pageMode === SettingsPageMode.ADD_NEW &&
                    algorithmIndexToAdd === currentTabIndex && (_jsx(_Fragment, { children: _jsx(PrioritizationWeightInput, { canEdit: canEdit, pageMode: pageMode, onRemove: onRemove, prioritizationAlgorithms: prioritizationAlgorithms, algorithmCriteriaList: algorithmCriteriaList, acceptPrioritizations: acceptPrioritizations, setPageMode: setPageMode, currentTabIndex: currentTabIndex, criteriaOptions: criteriaOptions, setAlgorithmIndexToAdd: setAlgorithmIndexToAdd, priorityCriteria: priorityCriteria }) })), canEdit && (_jsx(Button, { text: 'Add new', buttonType: 'customLink', onClick: () => {
                        setPageMode(SettingsPageMode.ADD_NEW);
                        setAlgorithmIndexToAdd(currentTabIndex);
                    }, isDisabled: algorithmCriteriaList.length >= 10 ||
                        pageMode === SettingsPageMode.ADD_NEW, icon: _jsx(AddIcon, {}), iconPosition: 'left' }))] }) }));
};
