var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { consolidateName } from '../conversion/NameConversions';
import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
import { convertIAssignUserAccessToUserAssignmentRequest, convertIConfigureUserProfileToUserProfileRequest, convertINewUserToInvitationRequest, convertUserProfileResponseToIUser, } from '../models/User/UserConversions';
export const useUserService = ({ platformApi }) => {
    const { handleResponse } = usePlatformResponseWrapper();
    const activateUser = (activateUser) => __awaiter(void 0, void 0, void 0, function* () {
        return handleResponse(platformApi.user.postApiV1UserActivate(activateUser), {
            error: {
                label: 'Error occurred activating the user',
                description: 'Your account may have already been activated. Please visit https://www.mysiteiq.com/ to log in or reset your password.',
                duration: null,
            },
            success: { enabled: false },
        }).then((res) => res);
    });
    const fetchUser = (userId) => __awaiter(void 0, void 0, void 0, function* () {
        return handleResponse(platformApi.user.getApiV1UserLoad(userId), {
            error: {
                label: 'Error occurred retrieving user information',
                ignoreStatus: [412],
            },
            success: { enabled: false },
        }).then(convertUserProfileResponseToIUser);
    });
    const configureUser = (userToUpdate) => __awaiter(void 0, void 0, void 0, function* () {
        const request = convertIConfigureUserProfileToUserProfileRequest(userToUpdate);
        return handleResponse(platformApi.user.postApiV1UserConfigure(request), {
            error: { label: 'Error occurred updating user' },
            success: {
                enabled: true,
                label: `Successfully updated user: ${consolidateName(userToUpdate)}`,
            },
        }).then(convertUserProfileResponseToIUser);
    });
    const assignUser = (name, userRolesToUpdate) => __awaiter(void 0, void 0, void 0, function* () {
        const request = convertIAssignUserAccessToUserAssignmentRequest(userRolesToUpdate);
        return handleResponse(platformApi.user.postApiV1UserAssign(request), {
            error: { label: 'Error occurred updating user' },
            success: {
                enabled: true,
                label: `Successfully updated access for user ${name}`,
            },
        }).then(convertUserProfileResponseToIUser);
    });
    const inviteUser = (newUser) => __awaiter(void 0, void 0, void 0, function* () {
        const request = convertINewUserToInvitationRequest(newUser);
        const name = consolidateName({
            firstName: newUser.firstName,
            lastName: newUser.lastName,
        });
        return handleResponse(platformApi.user.postApiV1UserInvite(request), {
            error: { label: 'Error occurred creating new user' },
            success: {
                enabled: true,
                label: `Successfully created user ${name}`,
            },
        }).then(convertUserProfileResponseToIUser);
    });
    const resendInviteUser = (userIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handleResponse(platformApi.user.postApiV1UserInviteResend({
            userId: userIdentity,
        }), {
            error: { label: 'Unable to resend invite' },
            success: {
                enabled: true,
                label: 'Invite sent',
            },
        });
    });
    const fetchUserList = (organizationIdentity, isPrimaryContactEligible) => __awaiter(void 0, void 0, void 0, function* () {
        return handleResponse(platformApi.user.getApiV1UserList(organizationIdentity, isPrimaryContactEligible)).then((res) => res.map(convertUserProfileResponseToIUser));
    });
    const offboardUsers = (users) => __awaiter(void 0, void 0, void 0, function* () {
        return handleResponse(platformApi.user.postApiV1UserOffboard({
            users: users.map((user) => ({
                userId: user.userIdentity,
                organizationId: user.organizationIdentity,
            })),
        }), {
            error: { label: 'Error occurred offboarding user' },
            success: {
                enabled: true,
                label: `Successfully offboarded user ${name}`,
            },
        }).then(() => {
            return;
        });
    });
    return {
        user: {
            activate: activateUser,
            fetch: fetchUser,
            fetchList: fetchUserList,
            update: configureUser,
            assign: assignUser,
            invite: inviteUser,
            resendInvite: resendInviteUser,
            offboard: offboardUsers,
        },
    };
};
