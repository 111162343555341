import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, } from '@chakra-ui/react';
import React from 'react';
import { variants } from '../../theme/theme';
export const NumberToggleInput = ({ data, onChange, minValue, maxValue, }) => {
    return (_jsx(_Fragment, { children: _jsxs(NumberInput, { "data-testid": 'prioritization', defaultValue: data, min: minValue, max: maxValue, minW: '80px', w: '80px', onChange: (value) => {
                onChange(value);
            }, variant: variants.outline, fontSize: '14px', children: [_jsx(NumberInputField, { textAlign: 'end', borderRadius: '8px' }), _jsxs(NumberInputStepper, { left: '10px', children: [_jsx(NumberIncrementStepper, { border: 'none', "data-testid": 'number-increment-stepper', children: _jsx(ChevronUpIcon, { boxSize: '18px' }) }), _jsx(NumberDecrementStepper, { border: 'none', children: _jsx(ChevronDownIcon, { boxSize: '18px' }) })] })] }) }));
};
