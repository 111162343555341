import { createGetHandler } from '../testing/msw/handlerUtils';
import { ProjectDetailResponseBuilder, ProjectSummaryResponseBuilder, ProjectTemplateResponseBuilder, } from '../testing/platformProjectMocks';
const projectEndpoints = {
    load: '/api/v1/Project/load/:projectId',
    list: '/api/v1/Project/list/:organizationId',
    configure: '/api/v1/Project/configure',
    loadTemplate: '/api/v1/Project/initialize/:organizationId',
};
const projectListHandler = createGetHandler(projectEndpoints.list, [
    new ProjectSummaryResponseBuilder({}).build(),
]);
const projectLoadHandler = createGetHandler(projectEndpoints.load, new ProjectDetailResponseBuilder({}).build());
const projectLoadTemplateHandler = createGetHandler(projectEndpoints.loadTemplate, new ProjectTemplateResponseBuilder({}).build());
const projectHandlers = [
    projectLoadHandler,
    projectLoadTemplateHandler,
    projectListHandler,
];
export const projectHandler = {
    endpoints: projectEndpoints,
    handlers: projectHandlers,
};
