import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Button, Flex, Heading, HStack, Text, Tooltip } from '@chakra-ui/react';
import { Dropdown } from '@frontend/design-system/components/Toolbar/Dropdown';
import { variants } from '@frontend/design-system/theme/theme';
import { AppPaths, AppRoutes, } from '@frontend/shared/src/components/Routing/RouteConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { appColors } from '../config/constants';
//TODO: remove duplication in all PageHeaders
/** Replaces old org base path with new org  */
const setNewOrgInPath = (path, newOrgIdentity, currentOrgIdentity) => {
    if (currentOrgIdentity) {
        return path.replace(AppPaths.organization.withOrgId(currentOrgIdentity), AppPaths.organization.withOrgId(newOrgIdentity));
    }
    else {
        return path;
    }
};
export const PageHeader = ({ title, hideOrgsDropdown, organizations, onSelectOrganization, selectedOrganizationIdentity, isLoading, dropdownLabel, showAddOrgButton, showAddUserButton, onOpenAddUser, }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const handleOrgChange = (orgIdentity) => {
        onSelectOrganization(orgIdentity);
        const url = setNewOrgInPath(location.pathname, orgIdentity, selectedOrganizationIdentity);
        navigate(url);
    };
    const showDropdown = !hideOrgsDropdown;
    const hasOrgsToRenderDropdown = showDropdown && organizations && organizations.length > 0;
    return (_jsx(_Fragment, { children: _jsx(Flex, { as: 'header', w: '100%', alignItems: 'center', px: 10, py: { base: 2, lg: 3 }, borderBottom: `1px solid ${appColors.BORDER_SEC_LIGHT_GRAY}`, children: _jsxs(HStack, { justifyContent: 'space-between', w: '100%', h: '100%', children: [_jsx(Tooltip, { label: title, placement: 'bottom-start', openDelay: 750, children: _jsx(Heading, { as: 'h1', fontSize: { base: '24px', xl: '32px' }, fontWeight: 400, color: appColors.TEXT_COLOR, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', children: title }) }), _jsxs(HStack, { gap: 10, children: [hasOrgsToRenderDropdown && (_jsx(Dropdown, { dropdownValues: organizations.map((org) => ({
                                    label: org.name,
                                    value: org.identity,
                                })) || [], label: dropdownLabel, isLoading: isLoading, handleChange: handleOrgChange, value: selectedOrganizationIdentity, bgColor: appColors.PRIM_BLUE })), showAddUserButton && (_jsxs(Button, { onClick: onOpenAddUser, w: 'auto', minW: 'auto', height: { base: '28px', lg: '36px' }, display: 'flex', justifyContent: 'center', alignItems: 'center', variant: variants.transparentBlueBtn, children: [_jsx(AddIcon, { mr: 2, boxSize: '16px', w: '9px', h: '9px' }), _jsx(Text, { color: appColors.PRIM_BLUE, children: "New User" })] })), showAddOrgButton && (_jsxs(Button, { onClick: () => {
                                    navigate(AppRoutes.createOrganization());
                                }, w: 'auto', minW: 'auto', height: { base: '28px', lg: '36px' }, display: 'flex', justifyContent: 'center', alignItems: 'center', variant: variants.transparentBlueBtn, children: [_jsx(AddIcon, { mr: 2, boxSize: '16px', w: '9px', h: '9px' }), _jsx(Text, { color: appColors.PRIM_BLUE, children: "New Organization" })] }))] })] }) }) }));
};
