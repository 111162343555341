var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Icon, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { chakraComponents, CreatableSelect, Select, } from 'chakra-react-select';
import { MdArrowDropDown } from 'react-icons/md';
import Body from '../Typography/Body/Body';
import InputWrapper from './InputWrapper';
import { getChakraStyles } from './models';
const ComboboxAtom = ({ size = 'medium', label, value, placeholder, isMultiSelect, options, 
// maxLength = 30,
onChange, renderOption, renderSelection, filterOption, onInputChange, createOption, isDisabled = false, isInvalid = false, id, }) => {
    const customComponents = {
        Option: (_a) => {
            var props = __rest(_a, []);
            const isCreateOption = props.data.__isNew__;
            return (_jsx(chakraComponents.Option, Object.assign({}, props, { children: renderOption ? (renderOption(props.data)) : (_jsx(Flex, { alignItems: 'center', flex: '1', gap: 2, children: _jsxs(Body, { noOfLines: 1, children: [isCreateOption ? 'Create: ' : null, props.data.label] }) })) })));
        },
        DropdownIndicator: () => {
            return (_jsx(Flex, { px: 2, children: _jsx(Icon, { w: 6, h: 6, as: MdArrowDropDown }) }));
        },
        SingleValue: (_a) => {
            var props = __rest(_a, []);
            return (_jsx(chakraComponents.SingleValue, Object.assign({}, props, { children: renderSelection ? (renderSelection(props.data)) : (_jsx(Flex, { alignItems: 'center', flex: '1', gap: 2, children: _jsx(Body, { noOfLines: 1, children: props.data.label }) })) })));
        },
        IndicatorSeparator: () => {
            return _jsx(_Fragment, {});
        },
    };
    const inputHeight = size === 'small' ? '24px' : '36px';
    return (
    //in order to get the component in the test it is needed to do the following
    //screen.getByTestId('test.id').querySelector('input')
    _jsx(Flex, { w: 'full', minH: inputHeight, "data-testid": id, children: _jsxs(InputGroup, { children: [label ? (_jsx(InputLeftAddon, { borderLeftRadius: '8px', color: 'white', background: 'primary.blue.500', px: 3, h: 'full', fontSize: '14px', borderColor: 'primary.blue.500', children: label })) : null, createOption ? (_jsx(CreatableSelect, { isDisabled: isDisabled, menuPortalTarget: document.body, value: value, onChange: onChange, placeholder: placeholder, isSearchable: true, isMulti: isMultiSelect, isInvalid: isInvalid, options: options, components: customComponents, chakraStyles: getChakraStyles(inputHeight, label), isValidNewOption: createOption === null || createOption === void 0 ? void 0 : createOption.isValidNewOption, onCreateOption: createOption === null || createOption === void 0 ? void 0 : createOption.onCreate, filterOption: filterOption, onInputChange: onInputChange, getNewOptionData: (inputValue) => 
                    // Not a fan of casting to T here, but we need to satisfy Typescript and these are the only fields required
                    // The user of this component will get the new string and construct the correct object of type T
                    ({
                        label: inputValue,
                        value: inputValue,
                        __isNew__: true,
                    }) })) : (_jsx(Select, { menuPortalTarget: document.body, value: value, placeholder: placeholder, onChange: onChange, isSearchable: true, isMulti: isMultiSelect, isInvalid: isInvalid, options: options, components: customComponents, isDisabled: isDisabled, filterOption: filterOption, onInputChange: onInputChange, chakraStyles: getChakraStyles(inputHeight, label) }))] }) }));
};
const Combobox = ({ options, onChange, renderSelection, renderOption, value, selectLabel, errorMessage, label, size, isRequired = false, isMultiSelect = false, createOption, filterOption, onInputChange, placeholder, isDisabled = false, id, }) => {
    return (_jsx(InputWrapper, { label: label, error: errorMessage, isRequired: isRequired, size: size, children: _jsx(ComboboxAtom, { size: size, options: options, onChange: onChange, value: value, isMultiSelect: isMultiSelect, label: selectLabel, createOption: createOption, renderOption: renderOption, renderSelection: renderSelection, filterOption: filterOption, onInputChange: onInputChange, placeholder: placeholder, isDisabled: isDisabled, isInvalid: !!errorMessage, id: id }) }));
};
export default Combobox;
