export const formatBudgetToString = (budgetNum) => {
    if (budgetNum === undefined) {
        return '';
    }
    else if (budgetNum === 0) {
        return '$0';
    }
    else {
        return `$${new Intl.NumberFormat().format(budgetNum)}`;
    }
};
export var CriteriaType;
(function (CriteriaType) {
    CriteriaType["UserDefined"] = "UserDefined";
    CriteriaType["Condition"] = "Condition";
})(CriteriaType || (CriteriaType = {}));
export var ProjectRelationshipType;
(function (ProjectRelationshipType) {
    ProjectRelationshipType["REQUIRES"] = "Requires";
    ProjectRelationshipType["RECOMMENDED"] = "Recommended";
    ProjectRelationshipType["ALTERNATE"] = "Alternate";
    ProjectRelationshipType["INTERDEPENDENT"] = "Interdependent";
    ProjectRelationshipType["REQUIRED_BY"] = "RequiredBy";
})(ProjectRelationshipType || (ProjectRelationshipType = {}));
export var ProjectStatusResponseEnum;
(function (ProjectStatusResponseEnum) {
    ProjectStatusResponseEnum["NEW"] = "New";
    ProjectStatusResponseEnum["DEFERRED"] = "Deferred";
    ProjectStatusResponseEnum["FUNDED"] = "Funded";
    ProjectStatusResponseEnum["IN_PROGRESS"] = "InProgress";
    ProjectStatusResponseEnum["COMPLETE"] = "Complete";
})(ProjectStatusResponseEnum || (ProjectStatusResponseEnum = {}));
// order of FriendlyProjectStatusEnum should not change (used for sorting)
export var FriendlyProjectStatusEnum;
(function (FriendlyProjectStatusEnum) {
    FriendlyProjectStatusEnum["COMPLETE"] = "Complete";
    FriendlyProjectStatusEnum["IN_PROGRESS"] = "In Progress";
    FriendlyProjectStatusEnum["FUNDED"] = "Funded";
    FriendlyProjectStatusEnum["DEFERRED"] = "Deferred";
    FriendlyProjectStatusEnum["NEW"] = "New";
})(FriendlyProjectStatusEnum || (FriendlyProjectStatusEnum = {}));
export const statusOrder = {
    [FriendlyProjectStatusEnum.NEW]: 1,
    [FriendlyProjectStatusEnum.DEFERRED]: 2,
    [FriendlyProjectStatusEnum.FUNDED]: 3,
    [FriendlyProjectStatusEnum.IN_PROGRESS]: 4,
    [FriendlyProjectStatusEnum.COMPLETE]: 5,
};
