var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { List, useToken } from '@chakra-ui/react';
import Button from '@frontend/design-system/components/Button/Button';
import { ContainerWithHeader } from '@frontend/design-system/components/DataDisplay/ContainerWithHeader';
import { SettingsPageMode, } from '@frontend/domain/models/Settings/SettingsInterfaces';
import { useState } from 'react';
import { validateName } from '../../../utils/formValidations';
import { SettingsInput } from './SettingsInput';
export const FundingTypesContainer = ({ orgFundingTypes, upsertFundingType, deleteFundingType, canEdit, }) => {
    const [light] = useToken('colors', ['secondary.light.300']);
    const [pageMode, setPageMode] = useState(SettingsPageMode.VIEW);
    const FUNDING_TYPE_NAME_ERROR_MSG = 'Funding Type name must be unique';
    const validateFundingType = (value, onInvalidName, onValidName) => {
        validateName(value, orgFundingTypes, onInvalidName, onValidName);
    };
    const handleSave = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield upsertFundingType(data);
        if (response) {
            setPageMode(SettingsPageMode.VIEW);
        }
        return response;
    });
    const onDelete = (data) => {
        deleteFundingType(data);
    };
    return (_jsx(ContainerWithHeader, { header: {
            label: 'Funding Types',
            button: canEdit && (_jsx(Button, { text: 'Add new', buttonType: 'secondary', onClick: () => {
                    setPageMode(SettingsPageMode.ADD_NEW);
                }, isDisabled: orgFundingTypes.length >= 20 ||
                    pageMode !== SettingsPageMode.VIEW, icon: _jsx(AddIcon, {}), iconPosition: 'left' })),
        }, children: _jsxs(List, { spacing: 3, border: `1px solid ${light}`, borderRadius: '2px', p: 5, height: 'calc(35vh)', overflow: 'auto', children: [pageMode === SettingsPageMode.ADD_NEW && (_jsx(SettingsInput, { canEdit: canEdit, pageMode: pageMode, onSave: handleSave, onDelete: onDelete, label: 'Funding Type', validateInput: validateFundingType, errorMessage: FUNDING_TYPE_NAME_ERROR_MSG, setPageMode: setPageMode, maxLength: 30 }, 'ft_new')), orgFundingTypes.length &&
                    orgFundingTypes.map((fundingType) => {
                        return (_jsx(SettingsInput, { canEdit: canEdit, pageMode: pageMode, originalData: fundingType, onSave: handleSave, onDelete: onDelete, label: 'Funding Type', validateInput: validateFundingType, errorMessage: FUNDING_TYPE_NAME_ERROR_MSG, setPageMode: setPageMode, maxLength: 30 }, `funding_type_${fundingType.name}`));
                    })] }) }));
};
