import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex, Grid } from '@chakra-ui/react';
import Input from '@frontend/design-system/components/Inputs/Input';
import { OnHoverWrapper } from '@frontend/design-system/components/Inputs/OnHoverWrapper';
import Select from '@frontend/design-system/components/Inputs/Select';
import BodyRegular from '@frontend/design-system/components/Typography/Body/Body';
import { SettingsPageMode, } from '@frontend/domain/models/Settings/SettingsInterfaces';
import { useState } from 'react';
import { SettingsEditDeleteBtnGroup } from './SettingsEditDeleteBtnGroup';
import { SettingsSaveCancelBtnGroup } from './SettingsSaveCancelBtnGroup';
export const ProjectTypeInput = ({ canEdit, pageMode, originalData, onDelete, validateInput, onSave, setPageMode, options, }) => {
    const [hoverMode, setHoverMode] = useState(false);
    const [validationError, setValidationError] = useState(undefined);
    const [projectTypeData, setProjectTypeData] = useState(originalData);
    const [isSelected, setIsSelected] = useState(false);
    const PROJECT_TYPE_NAME_ERROR_MSG = 'Project Type name must be unique';
    const defaultAlgorithm = Number(options[0].value);
    const onInputChange = (e) => {
        validateInput(e.target.value, () => setValidationError(PROJECT_TYPE_NAME_ERROR_MSG), () => setValidationError(undefined));
        setProjectTypeData((prevData) => (Object.assign(Object.assign({}, prevData), { name: e.target.value, algorithmId: (prevData === null || prevData === void 0 ? void 0 : prevData.algorithmId) || defaultAlgorithm })));
    };
    const onInputSelect = (selectedOption) => {
        setProjectTypeData((prevData) => (Object.assign(Object.assign({}, prevData), { name: (prevData === null || prevData === void 0 ? void 0 : prevData.name) || '', algorithmId: Number(selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value) })));
    };
    const onEdit = () => {
        setIsSelected(true);
        setPageMode(SettingsPageMode.EDIT);
    };
    const onCancel = () => {
        setPageMode(SettingsPageMode.VIEW);
        setProjectTypeData(originalData);
        setValidationError(undefined);
        setIsSelected(false);
    };
    const getAlgorithmLabel = () => {
        const algorithmOption = options.find((option) => option.value === (projectTypeData === null || projectTypeData === void 0 ? void 0 : projectTypeData.algorithmId));
        return (algorithmOption === null || algorithmOption === void 0 ? void 0 : algorithmOption.label) || options[0].label;
    };
    return (_jsxs(Flex, { flexDirection: 'column', children: [_jsx(OnHoverWrapper, { canEdit: canEdit && pageMode === SettingsPageMode.VIEW, setHoverMode: setHoverMode, customStyles: { justifyContent: 'normal', gap: '0' }, children: (pageMode === SettingsPageMode.EDIT && isSelected) ||
                    (pageMode === SettingsPageMode.ADD_NEW && !originalData) ? (_jsxs(Grid, { templateColumns: 'repeat(3, 1fr)', gap: 2, w: '100%', children: [_jsx(Input, { placeholder: 'Project Type', value: (projectTypeData === null || projectTypeData === void 0 ? void 0 : projectTypeData.name) || '', onChange: onInputChange, maxLength: 30 }), _jsx(Select, { options: options, value: {
                                label: getAlgorithmLabel(),
                                value: (projectTypeData === null || projectTypeData === void 0 ? void 0 : projectTypeData.algorithmId) ||
                                    defaultAlgorithm,
                            }, onChange: (value) => {
                                onInputSelect(value);
                            } }), _jsx(Box, { ml: 'auto', children: _jsx(SettingsSaveCancelBtnGroup, { btnLabel: 'Project Type', validationError: validationError, data: projectTypeData, onSave: () => {
                                    if (projectTypeData) {
                                        onSave(projectTypeData).then((response) => {
                                            if (response) {
                                                setIsSelected(false);
                                            }
                                        });
                                    }
                                }, onCancel: onCancel }) })] })) : (_jsxs(Grid, { templateColumns: 'repeat(3, 1fr)', gap: 2, w: '100%', children: [_jsx(BodyRegular, { children: projectTypeData === null || projectTypeData === void 0 ? void 0 : projectTypeData.name }), _jsx(BodyRegular, { children: getAlgorithmLabel() }), hoverMode &&
                            originalData &&
                            pageMode === SettingsPageMode.VIEW &&
                            canEdit && (_jsx(Box, { ml: 'auto', children: _jsx(SettingsEditDeleteBtnGroup, { btnLabel: 'Project Type', data: originalData, onEdit: onEdit, onDelete: onDelete, disableDelete: originalData.isInUse }) }))] })) }), validationError && (_jsx(BodyRegular, { mt: '5px', color: 'ui.danger', children: validationError }))] }));
};
