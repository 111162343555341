import { createPostHandler } from '../testing/msw/handlerUtils';
import { PlanResponseBuilder } from '../testing/planMocks';
const planEndpoints = {
    load: '/api/v1/Plan/load',
};
const planLoadHandler = createPostHandler(planEndpoints.load, [
    new PlanResponseBuilder({}).build(),
]);
const planHandlers = [planLoadHandler];
export const planHandler = {
    endpoints: planEndpoints,
    handlers: planHandlers,
};
