import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex } from '@chakra-ui/react';
import Input from '@frontend/design-system/components/Inputs/Input';
import { OnHoverWrapper } from '@frontend/design-system/components/Inputs/OnHoverWrapper';
import BodyRegular from '@frontend/design-system/components/Typography/Body/Body';
import { SettingsPageMode } from '@frontend/domain/models/Settings/SettingsInterfaces';
import { useState } from 'react';
import { SettingsEditDeleteBtnGroup } from './SettingsEditDeleteBtnGroup';
import { SettingsSaveCancelBtnGroup } from './SettingsSaveCancelBtnGroup';
export const SettingsInput = ({ canEdit, pageMode, originalData, validateInput, onSave, onDelete, label, errorMessage, maxLength, displayDelete = true, setPageMode, }) => {
    const [hoverMode, setHoverMode] = useState(false);
    const [validationError, setValidationError] = useState(undefined);
    const [inputData, setInputData] = useState(originalData);
    const [isSelected, setIsSelected] = useState(false);
    const onInputChange = (e) => {
        validateInput &&
            validateInput(e.target.value, () => setValidationError(errorMessage), () => setValidationError(undefined));
        setInputData((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value })));
    };
    const onEdit = () => {
        setIsSelected(true);
        setPageMode(SettingsPageMode.EDIT);
    };
    const onCancel = () => {
        setPageMode(SettingsPageMode.VIEW);
        setValidationError(undefined);
        setInputData(originalData);
        setIsSelected(false);
    };
    return (_jsxs(Flex, { flexDirection: 'column', gap: 1, children: [_jsxs(OnHoverWrapper, { canEdit: canEdit && pageMode === SettingsPageMode.VIEW, setHoverMode: setHoverMode, customStyles: {
                    justifyContent: 'space-between',
                }, children: [(pageMode === SettingsPageMode.EDIT && isSelected) ||
                        (pageMode === SettingsPageMode.ADD_NEW && !originalData) ? (_jsxs(_Fragment, { children: [_jsx(Input, { placeholder: label, value: (inputData === null || inputData === void 0 ? void 0 : inputData.name) || '', onChange: onInputChange, maxLength: maxLength }), _jsx(SettingsSaveCancelBtnGroup, { btnLabel: label, validationError: validationError, data: inputData, onSave: () => {
                                    if (inputData) {
                                        onSave(inputData).then((response) => {
                                            if (response) {
                                                setIsSelected(false);
                                            }
                                        });
                                    }
                                }, onCancel: onCancel })] })) : (_jsx(BodyRegular, { children: inputData === null || inputData === void 0 ? void 0 : inputData.name })), hoverMode &&
                        originalData &&
                        pageMode === SettingsPageMode.VIEW &&
                        canEdit && (_jsx(SettingsEditDeleteBtnGroup, { btnLabel: label, data: originalData, onEdit: onEdit, onDelete: onDelete, disableDelete: originalData === null || originalData === void 0 ? void 0 : originalData.isInUse, displayDelete: displayDelete }))] }), validationError && (_jsx(BodyRegular, { color: 'ui.danger', children: validationError }))] }));
};
