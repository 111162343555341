import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack } from '@chakra-ui/react';
import Button from '@frontend/design-system/components/Button/Button';
import { BiSolidTrashAlt } from 'react-icons/bi';
import { MdEdit } from 'react-icons/md';
export const SettingsEditDeleteBtnGroup = ({ btnLabel, data, onEdit, onDelete, displayDelete = true, disableDelete, }) => {
    return (_jsxs(HStack, { gap: 0, children: [_jsx(Button, { isIcon: true, ariaLabel: `Edit ${btnLabel}`, buttonType: 'customLink', icon: _jsx(MdEdit, {}), onClick: () => {
                    onEdit(data);
                } }), displayDelete && (_jsx(Button, { isIcon: true, ariaLabel: `Delete ${btnLabel}`, buttonType: 'customLink', icon: _jsx(BiSolidTrashAlt, {}), onClick: () => onDelete === null || onDelete === void 0 ? void 0 : onDelete(data), isDisabled: disableDelete }))] }));
};
