var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { List, useToken } from '@chakra-ui/react';
import { ContainerWithHeader } from '@frontend/design-system/components/DataDisplay/ContainerWithHeader';
import { SettingsPageMode, } from '@frontend/domain/models/Settings/SettingsInterfaces';
import { useState } from 'react';
import { SettingsInput } from './SettingsInput';
export const FacilityGroupersContainer = ({ orgFacilityGroupers, updateFacilityGrouperName, canEdit, }) => {
    const [light] = useToken('colors', ['secondary.light.300']);
    const [pageMode, setPageMode] = useState(SettingsPageMode.VIEW);
    const handleSave = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield updateFacilityGrouperName(data);
        if (response) {
            setPageMode(SettingsPageMode.VIEW);
        }
        return response;
    });
    return (_jsx(ContainerWithHeader, { header: { label: 'Rename Facility Groupers' }, children: _jsx(List, { spacing: 3, border: `1px solid ${light}`, borderRadius: '2px', p: 5, overflow: 'auto', w: '100%', height: 'calc(35vh)', children: orgFacilityGroupers.length &&
                orgFacilityGroupers.map((grouper) => {
                    return (_jsx(SettingsInput, { canEdit: canEdit, pageMode: pageMode, onSave: handleSave, originalData: grouper, setPageMode: setPageMode, label: 'Facility Grouper', displayDelete: false }, `facility_grouper_${grouper.grouperIndex}`));
                }) }) }));
};
