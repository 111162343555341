import { createGetHandler, createPostHandler, } from '../testing/msw/handlerUtils';
import { SettingsResponseBuilder } from '../testing/platformSettingsMocks';
const settingsEndpoints = {
    load: '/api/v1/Settings/load/{organizationId}',
    configure: '/api/v1/Settings/configure',
};
const settingsLoadHandler = createGetHandler(settingsEndpoints.load, new SettingsResponseBuilder({})
    .addFundingType({})
    .addProjectType({})
    .addProcurementType({})
    .addPriorityAlgorithm({})
    .addPriorityCriteria({})
    .addFacilityGrouper({})
    .build());
const settingsConfigureHandler = createPostHandler(settingsEndpoints.configure, new SettingsResponseBuilder({}).build());
const settingsHandlers = [settingsLoadHandler, settingsConfigureHandler];
export const settingsHandler = {
    endpoints: settingsEndpoints,
    handlers: settingsHandlers,
};
